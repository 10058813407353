<template lang="pug">
    el-dialog( title="Program de lucru" :visible.sync="showPopup" class="my-dialog-class" )
        el-form( label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
            el-row( :gutter="15" )
                el-col( :span="24" )
                    el-form-item( label="Nume" prop="Nume"  :required="true" )
                        el-input( v-model="selectedObject.Nume" )
                el-col( :span="12" )
                    el-form-item( label="Durata de lucru" prop="DurataDeLucru"  :required="true" )
                        el-input( v-model="selectedObject.DurataDeLucru" required)
                el-col( :span="12" )
                    el-form-item( label="Durata de pauza" prop="DurataDePauza"  :required="true" )
                        el-input( v-model="selectedObject.DurataDePauza" required)

                el-col( :span="24" ) 
                    el-form-item( label="Tip program" prop="Tip"  )
                        el-select( v-model="selectedObject.Tip" size='mini' class='full-width')
                            el-option( v-for="(t,idx) in Tipuri" :key="'tip-'+idx" :label="t" :value="t" ) 

                el-col( :span="8" v-if="selectedObject.Tip =='automat' || selectedObject.Tip =='fix'" )
                    el-form-item( label="Ora intrare" prop="OraIntrare"   )
                        el-input( v-model="selectedObject.OraIntrare")

                el-col( :span="8" v-if="selectedObject.Tip =='automat' || selectedObject.Tip =='fix'")
                    el-form-item( label="Ora iesire" prop="OraIesire" )
                        el-input( v-model="selectedObject.OraIesire")

                el-col( :span="24" )
                    el-form-item( label="Scade automat pauza in rapoarte" prop="PontajAutomat"  )
                        el-tooltip( content='Se foloseste in zonele unde nu se ponteaza pauza, ci doar intrare/iesire' )
                            el-checkbox( v-model="selectedObject.ScadeAutomatPauza" true-label="1" false-label="0") Scade automat pauza la rapoarte
                el-col( :span="10" )
                    el-form-item( label="Minim de ore lucrate pentru a scadea pauza automat" prop="MinimOrePentruScadere"  )
                        el-tooltip( content='Numai daca bifa ScadeAutomatPauza este activa' )
                            el-input( v-model="selectedObject.MinimOrePentruScadere" true-label="1" false-label="0" placeholder = "Numar minim ore" :disabled = "selectedObject.ScadeAutomatPauza == 0") Scade automat pauza la rapoarte
        

                el-col( :span="24" ) 
                    el-form-item( label="Rotunjeste automat la rapoarte" prop="RotunjireOreLaRaport"  )
                        el-tooltip( content='Se foloseste in zonele unde Total Ore Lucrate (TOL) si Ore Suplimentare (OS) trebuie rotunjite' )
                            el-checkbox( v-model="selectedObject.RotunjireOreLaRaport" true-label="1" false-label="0") Rotunjire activa la rapoarte
                
                el-col( :span="24" )
                    el-form-item( label="Suprascrie orele pontate cu pontajul automat" prop="SuprascrierePontajAutomat"  )
                        el-tooltip(content='Suprascrie orele pontate cu pontajul automat')
                            el-checkbox( v-model="selectedObject.SuprascrierePontajAutomat" true-label='1' false-label='0' ) Suprascrie orele pontate cu pontajul automat

        span( slot="footer" class="dialog-footer" ) 
            el-button( @click="showPopup=false" ) Renunta
            el-button( type="primary" @click="save") Salveaza
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';

export default {
  name: "programe-lucru-dialog",
  extends: BaseComponent,
  components: { },
  data () {
    return { 
      showPopup: false,
      mode: 'add',
      selectedObject: { Nume: "", DurataDeLucru: "08:00", DurataDePauza: "00:00", Tip: "flexibil" },
      Tipuri: [ "flexibil", "automat", "fix" ],
      rules: {
          Nume: [ { required: true, message: "Numele este obligatoriu" } ],
          DurataDeLucru: [ { validator: settings.checkTimeRule, trigger: "blur" } ],
          DurataDePauza: [ { validator: settings.checkTimeRule, trigger: "blur"  } ]
           }
    }
  },
  methods: {
     show_me: async function( id ) {
         this.showPopup        = true;
        if( id == null ) 
        {
            this.mode                = "add";
            this.selectedObject.Nume = "";
        }
        else
        {
            this.mode             = "edit";
            var            result = await this.post("get-programe-lucru", { id: id } );
            this.selectedObject   = result.Item;
        }
        console.log({selectedObject:this.selectedObject})
    },
    // getNumarSecunde(data){
    //     var date = new Date(data)
    //     var seconds = Math.round(date.getTime() / 1000)
    //     console.log(date)
    //     return seconds
    // },
    save: async function() {
        console.log(this.selectedObject)
        this.$refs['my-form'].validate( async(valid) => {
          if (valid) 
          {
            await this.post("save-programe-lucru", { mode: this.mode, object: this.selectedObject } );
            this.showPopup = false;
            this.$emit("save");
          }
        });
    }
  },
  mounted: function() {
  }
};
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    
</style>